import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MoveDocumentIcon from '@/assets/developers/migrating-to-talefin.webp';
import Rocket from '@/assets/speed-performance.webp';
import Integration from '@/assets/developers/migrate.webp';
import QnA from '@/assets/developers/Q&A.webp';
import CustomerSuccessTeam from '@/assets/developers/customer-success-team.webp';
import { Provider, ImageWithContent } from '../../../components/Developers/Provider';
import CentreAlign from '../../../components/CentreAlign';
import { HighlightCardWithIcon } from '../../../components/HighlightCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <Provider mdxType="Provider">
      <Box textAlign="center" paddingY={3} mdxType="Box">
        <h4 {...{
          "id": "have-an-existing-relationship-with-a-data-aggregator",
          "style": {
            "position": "relative"
          }
        }}>{`Have an existing relationship with a data aggregator?`}<a parentName="h4" {...{
            "href": "#have-an-existing-relationship-with-a-data-aggregator",
            "aria-label": "have an existing relationship with a data aggregator permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <Box textAlign="left" mdxType="Box">
Changing to TaleFin couldn't be easier, with unprecedented speed-to-market and streamlined onboarding. We provide experts to assist 
and reduce the pain of migrating from a different service. Customer Journey assistance and metric mappings make the migrating process 
straightforward.
      </Box>
      <Box padding={5} mdxType="Box">
        <HighlightCardWithIcon icon={MoveDocumentIcon} mdxType="HighlightCardWithIcon">
          <p>{`Combined with our Customer Success Team, we can have you up and running within hours. Complex migrations are made easier with support from our experienced team. TaleFin can reanalyse data from any structured source you provide.`}</p>
          <p>{`Send us your data, either in JSON, XLS or any other data format, and our team can process it.`}</p>
          <p>{`We will analyse the data to allow you to compare and match while building baseline models to work from.`}</p>
          <p>{`Compare us to your current data aggregator, with our team offering custom and comparative metrics to match any you are currently using.`}</p>
          <p>{`You can go live with our fast and secure iFrame within minutes. Alternatively, you can access our API by contacting us and we will help you set it up and start accepting traffic.`}</p>
          <p>{`We enable vendors to create dynamic application journeys using real-time analysis. This enables you to streamline & optimise the customer application process while providing a great experience.`}</p>
        </HighlightCardWithIcon>
      </Box>
      <Box mdxType="Box">
        <Grid container alignItems="center" alignItems="center" mdxType="Grid">
          <Grid container item sm={12} md={6} mdxType="Grid">
            <ImageWithContent src={Rocket} mdxType="ImageWithContent">
We offer unbeatable value, 99.99% service uptime, and over 170+ bank connections.
            </ImageWithContent>
          </Grid>
          <Grid item sm={12} md={6} mdxType="Grid">
            <ImageWithContent src={QnA} mdxType="ImageWithContent">
Software Engineers to answer your questions and expedite your integration.
            </ImageWithContent>
          </Grid>
          <Grid item sm={12} md={6} mdxType="Grid">
            <ImageWithContent src={Integration} mdxType="ImageWithContent">
TaleFin makes it easier to migrate by providing all your field mapping needs and recommendations.
            </ImageWithContent>
          </Grid>
          <Grid item sm={12} md={6} mdxType="Grid">
  <ImageWithContent src={CustomerSuccessTeam} mdxType="ImageWithContent">
    Our Customer Success Team partners with you to engineer an optimum customer
    experience. We maintain this partnership with you well into the future.
  </ImageWithContent>
          </Grid>
        </Grid>
      </Box>
    </Provider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      